body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: 100vh;
    position: relative;
}

html,
body {
    margin: 0;
    padding: 0;
}

html {
    width: 100vw;
    height: var(--app-height, 100vh);
}

body,
#root {
    margin: 0;
    min-width: 1920px;
    overflow-y: hidden;
    width: inherit;
    height: inherit;
}

html,
body {
    overscroll-behavior: none;
    /*user-select: none;*/
}

* {
    box-sizing: border-box;
}

p {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #CBCBCB;
    background-clip: padding-box;
    border-radius: 20px;
}

/* SVN-Gilroy */
@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Bold.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Bold Italic.otf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Italic.otf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Light.otf') format('truetype');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Light Italic.otf') format('truetype');
    font-weight: lighter;
    font-style: italic;
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('./fonts/SVN-Gilroy Medium.otf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}